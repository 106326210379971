<template>
  <v-layout class="mb-4 pb-4" wrap align-start>
    <v-flex xs12>
      <Title :title="`${id === 'nuevo' ? 'Nueva' : 'Editar'} pregunta frecuente`" subtitle=""></Title>
    </v-flex>
    <v-flex xs12>
      <v-card>
        <v-card-text>
          <v-container grid-list-lg fluid>
            <v-layout row wrap>
              <v-flex xs12 sm12 md12>
                <v-text-field v-model="ask" label="Pregunta" hide-details></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <QuillEditorFormat v-model="answer" :reference="'quill-editor-format'" />
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-radio-group v-model="is_active" class="pa-0 ma-0" :mandatory="false">
                  <v-radio color="secondary" label="Inactivo" :value="false"></v-radio>
                  <v-radio color="secondary" label="Activo" :value="true"></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field v-model="position" label="Posición" hide-details></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="accent" :loading="loading" :disabled="loading" flat @click="save">
            Listo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { CONFIG } from '../../config'
import Title from '../useful/title.vue'
import QuillEditorFormat from '../useful/quillEditorFormat.vue'

export default {
  name: 'FaqId',
  components: {
    Title,
    QuillEditorFormat
  },
  props: ['id'],
  data() {
    return {
      ask: '',
      answer: '',
      position: 0,
      is_active: false,
      loading: false
    }
  },
  mounted() {
    if (this.id !== 'new') {
      this.$http(`${CONFIG}/faq/${this.id}`).then(res => {
        const item = res.data
        this.ask = item.ask
        this.answer = item.answer
        this.is_active = item.is_active
        this.position = item.position
      })
    }
  },
  methods: {
    async save() {
      this.loading = true
      try {
        await this.$http({
          method: this.id ? 'put' : 'post',
          url: this.id ? `${CONFIG}/faq/${this.id}` : `${CONFIG}/faq`,
          data: {
            item: {
              ask: this.ask,
              answer: this.answer,
              is_active: this.is_active,
              position: this.position
            }
          }
        })
        this.loading = false
        this.$router.push({ name: 'settingsFaq' })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    }
  }
}
</script>
